class WishlistIcon extends HTMLElement {
  constructor() {
      super();
      this.isB2B = document.body.classList.contains('body--b2b');
      this.freezeClass = 'wishlist-icon--freeze'; // when an item is waiting for API answers, other items are frozen so that user has to wait for answer on one item before he/she can interact with another
      this.loadingClass = 'wishlist-icon--loading'; // for when page is loading and when user interacts with an item (when you are waiting for data)
      this.wishlistDataElement = document.querySelector('wishlist-data');
      this.init();
      document.addEventListener('removeLoadAndFreezeAnimations', () => {this.unfreezeAndRemoveLoadingAnimation();})
      document.addEventListener('freezeAllButOne', (e) => {this.freeze(e);})
  }

  /**
   * Set initial data and eventlisteners for when the wishlist-icon
   * element is used on product cards and product detail page, places
   * where you can add or remove single items to/from wish list
   */
  init() {

    this.productId = parseInt(this.getAttribute('data-wishlist-product-id'), 10);
    this.variantId = parseInt(this.getAttribute('data-wishlist-variant-id'), 10);
    this.addedClass = 'wishlist-icon--added';
    this.justRemovedClass = 'wishlist-icon--just-removed';
    this.justAddedOrUpdatedClass = 'wishlist-icon--just-added-or-updated';

    this.btn = this.querySelector('.wishlist-icon__button');
    this.isCurrentlyAdded = this.classList.contains(this.addedClass);
    if(this.btn) {
      this.btn.addEventListener('click', (event) => {
        event.stopPropagation();
        event.preventDefault();
        if(this.btn.tagName.toLowerCase() === 'button') {
          if(!this.classList.contains(this.freezeClass, this.loadingClass)) {
            this.toggleItemInWishlist(event.target);
            if(window.barcodescanningActive === true) {
              this.btn.blur();
            }
          }
        }
      });
      document.addEventListener('newWishlistDataLoaded', (e) => {this.updateProductItem(e);})
      document.addEventListener('newCollectionPageLoaded', (e) => {this.updateProductItemAfterCollectionPageLoad(e);})
      document.addEventListener('newWishlistPageLoaded', (e) => {this.updateProductItemAfterWishlistPageLoad(e);})
    }

    // if wishlist already exists and it's not the wish list page, means
    // element is lazyloaded and should just look at existing wishlist, no
    // need to load the wishlist data again
    // element also counts as lazyloaded if there's no user and no wishlist
    const isWishlistPage = this.closest('wishlist-page-items');
    if(!isWishlistPage && window.currentWishlist) {
      this.updateProductItemAfterLazyLoad();
    }else if(!window.customer && !window.localStorage.getItem('wishlistSessionId')){
      this.unfreezeAndRemoveLoadingAnimation();
    }
  }

  /**
   * Freeze wishlist icon when user is not allowed to interact with it because another item is waiting for response from API
   */
  freeze(e) {
    if(!e || !e.detail || !e.detail.itemNotToFreeze || e.detail.itemNotToFreeze !== this) {
      this.classList.add(this.freezeClass);
    }
  }

  /**
   * Add loading animation when user is waiting for answer from API and is not allowed to interact with item
   */
  addLoadingAnimation() {
    this.classList.add(this.loadingClass);
  }

  /**
   * Unfreeze wishlist icon and remove loading animation when user is allowed to interact with it again
   */
  unfreezeAndRemoveLoadingAnimation() {
    this.classList.remove(this.freezeClass, this.loadingClass);
  }

  updateProductItemAfterLazyLoad() {
    if(this.classList.contains(this.loadingClass)) {
      this.updateProductItem();
      this.unfreezeAndRemoveLoadingAnimation();
    }
  }

  updateProductItemAfterCollectionPageLoad(event) {
    if(this.classList.contains(this.loadingClass)) {
      this.updateProductItem(event);
      this.unfreezeAndRemoveLoadingAnimation();
    }
  }

  updateProductItemAfterWishlistPageLoad(event) {
    if(this.classList.contains(this.loadingClass)) {
      this.updateProductItem(event);
      this.unfreezeAndRemoveLoadingAnimation();
    }
  }

  /**
   * Called when the newWishlistDataLoaded event is fired
   * For updating the icon on the current product item to correspond to
   * the new data for the wish list that was just fetched
   * @param {event} event
   */
  updateProductItem(event) {
    //check if the prod id is on the list, if not the heart should be outlined
    let isInList;
    if(event) {
      isInList = event.detail.allVariantIds.indexOf(this.variantId) !== -1;
    } else {
      isInList = window.currentWishlist.allVariantIds.indexOf(this.variantId) !== -1;
    }

    if(isInList) {
      this.classList.add(this.addedClass);
      this.isCurrentlyAdded = true;
      this.btn.setAttribute('title',this.btn.getAttribute('data-text-added'));
      this.btn.setAttribute('aria-label',this.btn.getAttribute('data-text-remove'))
    } else {
      this.classList.remove(this.addedClass)
      this.isCurrentlyAdded = false;
      this.btn.setAttribute('title',this.btn.getAttribute('data-text-not-added'));
      this.btn.setAttribute('aria-label',this.btn.getAttribute('data-text-add'))
    }
  }

  /**
   * handle an add, update or remove client side
   */
  async toggleItemInWishlist() {
    this.addLoadingAnimation();
    this.classList.remove(this.justRemovedClass)
    this.classList.remove(this.justAddedOrUpdatedClass)

    this.wishlistDataElement.freezeAllButOne(this);
    this.wishlistDataElement.handlePossibleLogout();
    if(!window.currentWishlist) {
      await this.wishlistDataElement.getOrCreate(false);
    }
    await this.toggleItemInAPI();
    if(this.isCurrentlyAdded) {
      this.classList.add(this.addedClass)
      this.isCurrentlyAdded = true;
    } else {
      this.classList.remove(this.addedClass);
      this.isCurrentlyAdded = false;
    }
    this.wishlistDataElement.removeLoadingAnimationFromAll();
  }

  /**
   * handle an add, update or remove server side
   */
  async toggleItemInAPI() {
    if(this.isCurrentlyAdded) {
      const removed = await this.wishlistDataElement.removeProduct(this.productId, this.variantId);
      if(removed) {
        this.isCurrentlyAdded = false;
        this.classList.add(this.justRemovedClass)
      }
    } else {
      const quantity = this.getQuantity();
      const addedOrUpdated = await this.wishlistDataElement.addOrUpdateProduct(this.productId, this.variantId,quantity);
      if(addedOrUpdated) {
        this.isCurrentlyAdded = true;
        this.classList.add(this.justAddedOrUpdatedClass)
      }
    }
  }

  /**
   * Get quantity that the product should be added with
   * On B2C always 1
   * On B2B whatever is chosen
   * @returns the quantity that the product should be added with
   */
  getQuantity() {
    if(!this.isB2B) {
      //for B2C
      return 1;
    } else {
      //for B2B
      let parent = this.closest('.card-wrapper') || this.closest('.product') || null; // if card or product page or null
      if(parent) {
        const qty = parseInt(parent.querySelector('.quantity__input').value, 10);
        return qty;
      } else {
        console.error('Wish list icon is not placed in product card or page');
      }
    }
  }
}
customElements.define('wishlist-icon', WishlistIcon);

class WishlistIconHeader extends WishlistIcon {
  constructor() {
      super();
      this.wishlistLink = this.closest('.header__icon--wishlist');
      this.wishlistLink.addEventListener('click', (e) => {this.handleLinkClick(e);})
  }

  /**
   * Set initial data and eventlisteners for when the wishlist-icon
   * element is used in the site header
   */
  init() {
    this.quantityHolder = document.getElementById('wishlist-icon__quantity');
    this.quantitySingularis = this.quantityHolder.getAttribute('data-singularis');
    this.quantityPluralis = this.quantityHolder.getAttribute('data-pluralis');
    document.addEventListener('newWishlistDataLoaded', (e) => {
      if(e.detail.wishlistPage === false) {
        this.updateHeaderQuantity();
      }
    })
  }

  /**
   * when you click on icon in header
   * if wishlist is not busy
   * go to wishlist page
   * @param {event} e
   */
  handleLinkClick(e) {
    this.wishlistDataElement.handlePossibleLogout();
    if(this.classList.contains(this.freezeClass, this.loadingClass)) {
      e.preventDefault();
    }
  }

  /**
   * Called when the newWishlistDataLoaded event is fired
   * For updating the quantity of products in the current wish list that
   * is shown together with the wish list icon in the site header
   */
  updateHeaderQuantity() {
    const quantity = parseInt(window.currentWishlist.productCount,10);

    if(quantity > 0) {
      this.classList.remove('wishlist-icon--header-empty');
      this.setAttribute('title',this.getAttribute('data-text-filled'));
      const quantityText = quantity === 1 ? this.quantitySingularis : this.quantityPluralis;
      this.quantityHolder.innerHTML = `<span id="wishlist-icon__quantity--visual" aria-hidden="true">${quantity}</span><span id="wishlist-icon__quantity--text" class="visually-hidden">${quantity} ${quantityText}</span>`;
    } else {
      this.classList.add('wishlist-icon--header-empty');
      this.setAttribute('title',this.getAttribute('data-text-empty'));
      this.quantityHolder.innerHTML = '';
    }
  }
}
customElements.define('wishlist-icon-header', WishlistIconHeader);
customElements.define('wishlist-error', class WishlistError extends HTMLElement {
  constructor() {
      super();
      this.init();
      this.wishlistDataElement = document.querySelector('wishlist-data');
  }

  init() {
    // when click on error message background or close button, close the message
    this.addEventListener('click', (e) => {
      if(e.target.classList.contains('wishlist-error__dialog') || e.target.classList.contains('wishlist-error__close')) {
        this.close();
      }
    })
  }

  /**
   * Open/show wishlist error message
   * @param {object} argMessageDetails, optional object with error message text, properties are 'header' and 'message'
   * @returns
   */
  open(errorMessage) {
    const templateEl = document.getElementById('wishlist-error-template');
    if(!templateEl) {return;}

    // if there's already an error message in the DOM, remove it
    const existingErrorEl = document.getElementById('wishlist-error__dialog');
    if(existingErrorEl) {
      existingErrorEl.remove();
    }

    let messageDetails = wishlistErrorTranslations.general;
    if(errorMessage && errorMessage.header && errorMessage.message) {
      messageDetails = errorMessage;
      messageDetails.message += ` ${wishlistErrorTranslations.serverErrorAddition}`;
    } else if(errorMessage && errorMessage.toLowerCase().includes('exceeding max product limit on a wishlist')) {
        messageDetails = wishlistErrorTranslations.maxLimit;
    } else if(errorMessage && errorMessage.toLowerCase().includes('merge wishlist error happened where total')) {
        messageDetails = wishlistErrorTranslations.maxLimitMerge;
    } else if(errorMessage && errorMessage.toLowerCase().includes('no wishlist found for provided sessionid')) {
      // call wishlist-data element to remove session id from local storage
      this.wishlistDataElement.removeSessionIdOnClient();
      if(window.customer && window.customer.customerId) {
        messageDetails = wishlistErrorTranslations.noListFoundFromSessionIdLoggedIn;
      } else {
        messageDetails = wishlistErrorTranslations.noListFoundFromSessionIdAnonymous;
      }
    } else if(errorMessage && errorMessage.toLowerCase().includes('no wishlist found for publicwishlistid')) {
        messageDetails = wishlistErrorTranslations.noListFoundFromPublicId;
    } else if(errorMessage && errorMessage.toLowerCase().includes('error 429')) {
      messageDetails = wishlistErrorTranslations.tooManyRequests;
    } else if(errorMessage === 'wishlistempty') {
      messageDetails = wishlistErrorTranslations.wishlistEmpty;
    }

    var cloneEl = templateEl.content.cloneNode(true);
    cloneEl.querySelector('.wishlist-error__heading').innerHTML = messageDetails.header;
    cloneEl.querySelector('.wishlist-error__message').innerHTML = messageDetails.message;
    this.appendChild(cloneEl);
    document.getElementById('wishlist-error__dialog').showModal();
  }

  /**
   * Close wishlist error message
   */
  close() {
    document.getElementById('wishlist-error__dialog').close();
  }
});

customElements.define('wishlist-data', class WishlistData extends HTMLElement {
  constructor() {
    super();
    this.handlePossibleLogout();
    this.handleEmptyWishlist();
    this.customElementError = document.querySelector('wishlist-error');

    // if user is logged in, we have to check wishlist at every pageload
    // because wishlist could be updated on another device in the meantime
    if(window.customer && window.customer.customerId) {
        this.userIsAnonymous = false;
        this.getOrCreate(true);
    } else {
      // if user has local storage item saying they already have a wishlist, we must check the wishlist
      // if not we will wait until the anonymous user interacts with the wishlist somehow
      this.userIsAnonymous = true;
      const hasWishlist = window.localStorage.getItem('wishlistSessionId');
      if(hasWishlist) {
        this.getOrCreate(true);
      } else {
        // since we can't be user what order elements are initialized in, we both dispatch an event and set a global var (so wish list page can know that it can initialize)
        window.anonymousUserNoWishlist = true;
        const event = new CustomEvent('anonymousUserNoWishlist');
        document.dispatchEvent(event);
        this.removeLoadingAnimationFromAll();
      }
    }
  }

  handleEmptyWishlist() {
    const urlParams = new URL(window.location.href).searchParams;
    if(urlParams.get('wishlistempty')) {
      window.addEventListener('load', () => {
        this.showErrorPopup('wishlistempty');
        window.history.replaceState({ }, '', `${window.location.origin}${window.location.pathname}`);
      })
    }
  }

  /**
   * check if user is logged out but the wishlist localstorage still says you are logged in
   * if there's something in the wishlist, then reload the page to empty it
   */
  handlePossibleLogout() {
    const wasLoggedInWhenPageLoaded = window.customer && window.customer.customerId;
    const wishlistLoggedInShopify = window.localStorage.getItem('wishlistLoggedInShopify');
    let possibleUserChange = false;
    if(wishlistLoggedInShopify) {
      if(wasLoggedInWhenPageLoaded) {
        if(parseInt(wishlistLoggedInShopify,10) !== parseInt(window.customer.customerId,10)) {
          possibleUserChange = true;
        }
      } else {
        possibleUserChange = true;
      }
    }

    if(possibleUserChange) {
      window.localStorage.removeItem('wishlistLoggedInShopify');
      window.localStorage.removeItem('wishlistSessionId');
      window.currentWishlist = null;

      if(wasLoggedInWhenPageLoaded) {
        location.reload();
      }
      // if there's something in the wishlist, we reload to get new data
      if(!document.querySelector('#wishlist-icon__quantity:empty')) {
        location.reload();
      }
    }
  }

  /**
   * used for clearing wishlist session id if API returns that it doesn't exist
   */
  removeSessionIdOnClient() {
    window.localStorage.removeItem('wishlistSessionId');
  }

  /**
   * Sanitize data that should be sent to server
   *
   * @param {object} argObj
   * @returns sanitized arguments for fetch
   */
  saneProperties(argObj) {
    let returnObj = {};

    if(argObj.productId) {
      returnObj.productId = parseInt(argObj.productId,10);
    }

    if(argObj.variantId) {
      returnObj.variantId = parseInt(argObj.variantId,10);
    }

    if(argObj.customerId) {
      returnObj.customerId = parseInt(argObj.customerId,10);
    }

    if(argObj.quantity) {
      returnObj.quantity = parseInt(argObj.quantity,10) > 0 ? parseInt(argObj.quantity,10) : 1;
    }

    if(argObj.sessionId) {
      //validate sessionId
      var sessionRegex = /([A-Za-z0-9-])/;
      var sessionValidationResult = sessionRegex.test(argObj.sessionId);
      if(!sessionValidationResult) {
        console.error('wrong session');
        return false;
      }
      returnObj.sessionId = argObj.sessionId;
    }

    return returnObj;
  }

  /**
   * Call another custom element to open wishlist error message
   */
  showErrorPopup(errorMessage) {
    this.customElementError.open(errorMessage);
  }

  /**
   * Handles the data that the server sends back when
   * adding/updating/removing items from wish list
   *
   * @param {json} returnedJson
   * @returns false if there is an error, otherwise true
   */
  handleNewData(returnedJson,wishlistPage,calledFrom) {
    let wishlistVariable = null;
    if(returnedJson.success) {
      if(!wishlistPage) {
        if(returnedJson.data.sessionId) {
          //save session id
          window.localStorage.setItem('wishlistSessionId',returnedJson.data.sessionId);
          if(window.customer && window.customer.customerId) {
            window.localStorage.setItem('wishlistLoggedInShopify',window.customer.customerId)
          } else {
            window.localStorage.removeItem('wishlistLoggedInShopify');
          }
        }
        else {
          //delete session id
          window.localStorage.removeItem('wishlistSessionId');
          window.localStorage.removeItem('wishlistLoggedInShopify');
        }
        wishlistVariable = 'currentWishlist';
      } else {
        wishlistVariable = 'wishlistPageShownWishlist';
      }
        window[wishlistVariable] = returnedJson.data;
        const allVariantIds =  returnedJson.data.products.map(x => x.variantId);
      window[wishlistVariable].allVariantIds = allVariantIds;
      const event = new CustomEvent('newWishlistDataLoaded',{detail:{wishlistPage:wishlistPage?true:false,allVariantIds:allVariantIds}});
      document.dispatchEvent(event);
      if(returnedJson.errorMessage) {
        console.error(`Error in handleNewData (from ${calledFrom}): ${returnedJson.errorMessage}`);
        this.showErrorPopup(returnedJson.errorMessage);
      }
      return true;
    } else {
      if(returnedJson.errorMessage) {
        console.error(`Error in handleNewData (from ${calledFrom}): ${returnedJson.errorMessage}`);
        this.showErrorPopup(returnedJson.errorMessage);
      }
      return false;
    }
  }

  /**
   * Add or update product on wish list on server
   *
   * @param {int} argProductId
   * @param {int} argVariantId
   * @param {int} argQuantity
   * @returns if success of adding/updating wish list is true/false
   */
  async addOrUpdateProduct(argProductId,argVariantId,argQuantity) {
    const saneData = this.saneProperties({productId: argProductId, variantId: argVariantId, sessionId: window.currentWishlist.sessionId, quantity: argQuantity});
    if(!saneData) {
      console.error('Error, addOrUpdateProduct data not valid');
      this.showErrorPopup();
      return;
    }

    let success = false;
    await fetch(`/apps/wishlist/addorupdateproduct?sessionId=${saneData.sessionId}&productId=${saneData.productId}&variantId=${saneData.variantId}&quantity=${saneData.quantity}`,{
      headers: {
        method: 'GET',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        SessionId: saneData.sessionId
      }
    }
    )
    .then((response) => {
      if(response.status === 429) {
        throw new Error('error 429');
      } else {
        return response.json();
      }
    })
    .then((returnedJson) => {
      success =  this.handleNewData(returnedJson,false,'addOrUpdateProduct');
      if(returnedJson.errorMessage) {
        console.error(`Error in addOrUpdateProduct: ${returnedJson.errorMessage}`);
        this.showErrorPopup(returnedJson.errorMessage);
      }
    }).catch(error => {
        console.error(`Error in addOrUpdateProduct (2): ${error}`);
        if(error.message && error.message.toLowerCase().includes('error 429')){
          this.showErrorPopup('error 429');
        } else {
          this.showErrorPopup();
        }
      }
    );
    return success;
  }

  /**
   * Remove product from wish list on server
   *
   * @param {int} argProductId
   * @param {int} argVariantId
   * @returns if success of removing from wish list is true/false
   */
  async removeProduct(argProductId,argVariantId) {
    const saneData = this.saneProperties({productId: argProductId, variantId: argVariantId, sessionId: window.currentWishlist.sessionId});
    if(!saneData) {
      console.error('Error, removeProduct data not valid');
      this.showErrorPopup();
      return;
    }
    let success = false;
    await fetch(`/apps/wishlist/removeproduct?sessionId=${saneData.sessionId}&productId=${saneData.productId}&variantId=${saneData.variantId}`,{
      headers: {
        method: 'GET',
        Accept: 'application/json',
        'Content-Type': 'application/json',
        SessionId: saneData.sessionId
      }
    })
    .then((response) => {
      if(response.status === 429) {
        throw new Error('error 429');
      } else {
        return response.json();
      }
    })
    .then((returnedJson) => {
      success = this.handleNewData(returnedJson,false,'removeProduct');
      if(returnedJson.errorMessage) {
        console.error(`Error in removeProduct: ${returnedJson.errorMessage}`);
        this.showErrorPopup(returnedJson.errorMessage);
      }
    }).catch(error => {
      console.error(`Error in removeProduct (2): ${error}`);
      if(error.message && error.message.toLowerCase().includes('error 429')){
        this.showErrorPopup('error 429');
      } else {
        this.showErrorPopup();
      }
    });
    return success;
  }

  /**
   * Get or create a wish list
   * If no wish list exists, a new one is created and returned, otherwise the existing is returned
   */
  async getOrCreate(makeClientSideData) {
    let dataString = '';
    const sessionIdFromStorage = window.localStorage.getItem('wishlistSessionId');
    if(sessionIdFromStorage) {
      const saneData = this.saneProperties({sessionId:sessionIdFromStorage});
      if(!saneData) {
        console.error('Error, getOrCreate data not valid (anonymous)');
        this.showErrorPopup();
        return;
      }
      const startChar = dataString ? '&' : '?';
      dataString += `${startChar}sessionId=${saneData.sessionId}`;
    }

    const customerIdFromWindow = window.customer?window.customer.customerId:null;
    if(customerIdFromWindow) {
      const saneData = this.saneProperties({customerId: customerIdFromWindow});
      if(!saneData) {
        console.error('Error, getOrCreate data not valid (customer)');
        this.showErrorPopup();
        return;
      }
      const startChar = dataString ? '&' : '?';
      dataString += `${startChar}CustomerId=${saneData.customerId}`;
    }

    let success = false;
    await fetch(`/apps/wishlist/getorcreate${dataString}`)
    .then((response) => {
      return response.json();
    })
    .then((returnedJson) => {
      if(makeClientSideData) {
        success =  this.handleNewData(returnedJson,false,'getOrCreate');
      } else {

        if(returnedJson.success) {
          if(returnedJson.data.sessionId) {
            window.localStorage.setItem('wishlistSessionId',returnedJson.data.sessionId);
            if(window.customer && window.customer.customerId) {
              window.localStorage.setItem('wishlistLoggedInShopify',window.customer.customerId)
            } else {
              window.localStorage.removeItem('wishlistLoggedInShopify');
            }
          }
          else {
            //delete session id
            window.localStorage.removeItem('wishlistSessionId');
            window.localStorage.removeItem('wishlistLoggedInShopify');
          }

          window.currentWishlist = returnedJson.data;
          if(returnedJson.errorMessage) {
            console.error(`Error in handleNewData: ${returnedJson.errorMessage}`);
            this.showErrorPopup(returnedJson.errorMessage);
          }
          //we do nothing here because this function is always called before another one that updates the wishlist as well
        } else {
          if(returnedJson.errorMessage) {
            console.error(`Error in getOrCreate: ${returnedJson.errorMessage}`);
            this.showErrorPopup(returnedJson.errorMessage);
          }
        }
      }
    }).catch(error => {
      console.error(`Error in getOrCreate (2): ${error}`);
      this.showErrorPopup();
    });

    if(makeClientSideData) {
      if(success) {
        this.removeLoadingAnimationFromAll();
      }
      return success;
    }
  }

  /**
   * Add or update product on wish list on server
   *
   * @param {int} argProductId
   * @param {int} argVariantId
   * @param {int} argQuantity
   * @returns if success of adding/updating wish list is true/false
   */
  async addMultipleProducts(data) {
    let saneData = {
      products:[]
    };
    const saneSessionId = this.saneProperties({sessionId: window.currentWishlist.sessionId});

    const sessionId = saneSessionId ? saneSessionId.sessionId : null;
    if(saneSessionId) {
      for (let i = 0; i < data.length; i++) {
        const element = data[i];
        const saneElementData = this.saneProperties({productId: element.productId, variantId: element.variantId, quantity: element.quantity});
        if(saneElementData) {
          saneData.products.push(saneElementData)
        } else {
          saneData = false;
          break;
        }
      }
    } else {
      saneData = false;
    }
    if(!saneData) {
      console.error('Error, addMultipleProducts data not valid');
      this.showErrorPopup();
      return false;
    }
    const products = saneData.products;
    let success = false;
    await fetch('/apps/wishlist/addorupdateproducts',{
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        SessionId: saneData.sessionId
      },
      body: JSON.stringify({ sessionId ,products})
    }
    )
    .then((response) => {
      if(response.status === 429) {
        throw new Error('error 429');
      } else {
        return response.json();
      }
    })
    .then((returnedJson) => {
      success =  this.handleNewData(returnedJson,false,'addMultipleProducts');
      if(returnedJson.errorMessage) {
        console.error(`Error in addOrUpdateProduct: ${returnedJson.errorMessage}`);
        this.showErrorPopup(returnedJson.errorMessage);
      }
    }).catch(error => {
        console.error(`Error in addMultipleProducts (2): ${error}`);
        if(error.message && error.message.toLowerCase().includes('error 429')){
          this.showErrorPopup('error 429');
        } else {
          this.showErrorPopup();
        }
      }
    );
    return success;
  }

  /**
   * Freeze all wishlist elements on the page except for
   * the one that has an action, this will be having a
   * load animation instead
   * @param {HTML element} itemNotToFreeze
   */
  freezeAllButOne(itemNotToFreeze) {
    const event = new CustomEvent('freezeAllButOne',{detail:{itemNotToFreeze:itemNotToFreeze}});
    document.dispatchEvent(event);
  }

  /**
   * if you are not logged in and do not have a wishlist already
   * we remove loading animation from all icons on the page immediately
   */
  removeLoadingAnimationFromAll() {
    const event = new CustomEvent('removeLoadAndFreezeAnimations');
    document.dispatchEvent(event);
  }
});

class AddCartToWishlist extends HTMLElement {
  constructor() {
    super();
    this.btn = this.querySelector('.underlined-link--cart-wishlist');
    this.confirmBox = document.getElementById('confirm-add-from-cart-to-wishlist');
    this.waitBox = document.getElementById('info-add-from-cart-to-wishlist');
    this.stickyHeader = document.querySelector('sticky-header');
    this.wishlistDataElement = document.querySelector('wishlist-data');
    this.wishlistIcon = this.querySelector('wishlist-icon-cart-add-all');
    this.confirmText = this.getAttribute('data-confirm-text');
    document.addEventListener('removeLoadAndFreezeAnimations', () => {this.enableButton();})
    this.btn.addEventListener('click', this.addCartContentsToWishlist.bind(this));
  }

  /**
  * show confirm box and wait for user to confirm if they
  * want to add all products in cart to the wish list or not
  *
  * @returns true if it is confirmed to add all and false if not
  */
  waitForUserConfirmAddCart() {
      this.confirmBox.showModal();
      return new Promise(resolve => {
          const confirmBtns = this.confirmBox.getElementsByClassName('js-button--confirm-box');

          const myCallback = (e) => {
              const isConfirmed = e.target.value === 'confirm';
              this.confirmBox.close();

              for (let i = 0; i < confirmBtns.length; i++) {
                  const btn = confirmBtns[i];
                  btn.removeEventListener('click', myCallback);
              }
              resolve(isConfirmed);
          }

          for (let i = 0; i < confirmBtns.length; i++) {
              const btn = confirmBtns[i];
              btn.addEventListener('click', myCallback);
          }
      })
  }

  async addCartContentsToWishlist(e) {
    this.wishlistIcon.classList.remove('wishlist-icon--all-added')
    this.disableButton();
    if(await this.waitForUserConfirmAddCart()) {
      e.preventDefault();
      e.stopPropagation();
      this.waitBox.showModal();

      this.wishlistDataElement.freezeAllButOne('none');
      this.wishlistDataElement.handlePossibleLogout();
      if(!window.currentWishlist) {
        await this.wishlistDataElement.getOrCreate(false);
      }

      const cartItems = document.querySelectorAll('.cart__contents tr.cart-item');
      const cartItemArray = [];
      cartItems.forEach(element => {
        const obj = {};
        obj.productId = element.getAttribute('data-product-id');
        obj.variantId = parseInt(element.getAttribute('data-lineitem-product-id'), 10);
        obj.quantity = parseInt(element.querySelector('.quantity__input').value, 10);
        cartItemArray.push(obj)
      });

      const multipleProductsAdded = await this.wishlistDataElement.addMultipleProducts(cartItemArray);

      if(multipleProductsAdded){
        this.wishlistDataElement.removeLoadingAnimationFromAll();
        this.stickyHeader.reveal();

        const tooltip = document.getElementById('wishlist-icon__empty-tooltip');
        const newTooltip = tooltip.cloneNode(true);
        newTooltip.setAttribute('id','wishlist-icon__empty-tooltip--message');
        newTooltip.classList.add('wishlist-icon__empty-tooltip--message');
        newTooltip.querySelector('.wishlist-icon__tooltip-text').innerHTML = this.confirmText;
        tooltip.after(newTooltip);
        setTimeout(()=>{
          const insertedTooltip = document.getElementById('wishlist-icon__empty-tooltip--message');
          if(insertedTooltip) {
            insertedTooltip.remove();
          }
        },3000)
        this.wishlistIcon.classList.add('wishlist-icon--all-added');
      }
      this.waitBox.close();
    }
    this.enableButton();
  }

  /**
   * make button available for clicking
   */
  enableButton(){
    this.btn.removeAttribute('disabled');
    this.wishlistIcon.classList.remove('wishlist-icon--loading')
  }

  /**
   * make button unavailable for clicking (when loading or
   * when waiting for confirm box)
   */
  disableButton(){
      this.btn.setAttribute('disabled','disabled');
      this.wishlistIcon.classList.add('wishlist-icon--loading')
  }
}
customElements.define('add-cart-to-wishlist', AddCartToWishlist);